<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    accordion="header-accordion"
    :class="{
      collapsed: visible,
      login: isLoggedIn,
      'not-login': !isLoggedIn,
      'no-product': this.cartCount === 0,
    }"
    :aria-expanded="visible"
    class="top-icons--drop mini-cart"
    :visible="visible"
  >
    <!-- v-model="visible" -->
    <div>
      <div v-if="cartCount > 0">
        <span v-if="icon.dropHeading" class="drop-heading">{{
          icon.dropHeading
        }}</span>

        <ul class="cart-drop-item">
          <li v-for="dropItem of icon.dropItems" :key="dropItem.product.sku">
            <div class="">
              <div class="top" @click.stop="removeItem(dropItem.id)">
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </div>
              <div class="single-item-wrap">
                <div class="first">
                  <div class="left">
                    <b-link :to="`/${dropItem.product.url_key}`">
                      <div class="drop-item--img">
                        <b-img
                          :src="dropItem.product.thumbnail.thumbnail"
                          :alt="dropItem.product.name"
                          width="75"
                          height="75"
                        >
                        </b-img>
                      </div>
                      <div class="product-info">
                        <span class="product-name d-block">{{
                          dropItem.product.name
                        }}</span>
                      </div>
                    </b-link>
                  </div>

                  <div class="right">
                    <div class="drop-item--info">
                      <div class="price-box price">
                        {{
                          formatCurrency(
                            dropItem.prices.row_total_including_tax.value
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="second">
                  <div class="quantity-price-box">
                    <div class="quantity-box">
                      <div class="quantity-input">
                        <b-link
                          class="step-down-btn"
                          @click.stop="
                            quantityDown(dropItem.id, dropItem.quantity)
                          "
                        ></b-link>
                        <input
                          type="number"
                          min="1"
                          :value="dropItem.quantity"
                          name="quantity"
                          ref="inputQuantity"
                          disabled
                        />
                        <b-link
                          class="step-up-btn"
                          @click.stop="
                            increaseQty(dropItem.id, dropItem.quantity)
                          "
                        ></b-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="bottom">
              <div v-if="typeof dropItem.configurable_options != 'undefined'">
                <span
                  v-for="(option, index) of dropItem.configurable_options"
                  :key="index"
                  class="product-options d-block"
                >
                  <span class="product-options-title"
                    >{{ option.option_label }}:&nbsp;</span
                  >
                  <span class="product-options-value">{{
                    option.value_label
                  }}</span>
                </span>
              </div>
              <div v-if="typeof dropItem.bundle_options != 'undefined'">
                <div>
                  <span
                    v-for="(option, index) of dropItem.bundle_options"
                    :key="index"
                    class="product-options d-block"
                  >
                    <span>{{ option.label }}: </span>

                    <span
                      v-if="
                        typeof option.values[0].configurable_options !=
                        'undefined'
                      "
                    >
                      <span
                        v-for="(opt, idx) of option.values[0]
                          .configurable_options"
                        :key="idx"
                      >
                        <span class="product-options-title"
                          >{{ opt.option_label }}&nbsp;:</span
                        >
                        <span class="product-options-value">{{
                          opt.value_label
                        }}</span>
                      </span>
                    </span>
                  </span>
                </div>
                <span
                  v-for="(option, index) of dropItem.configurable_options"
                  :key="index"
                  class="product-options d-block"
                >
                  <span class="product-options-title"
                    >{{ option.option_label }}&nbsp;:</span
                  >
                  <span class="product-options-value">{{
                    option.value_label
                  }}</span>
                </span>
              </div>
            </div> -->
            </div>
          </li>
        </ul>
        <div class="total">
          <span>
            Totaal (incl. BTW):
            {{ formatCurrency(icon.cartFooter.totalPrice) }}</span
          >
        </div>
        <div
          class="d-flex drop-bottom justify-content-between align-items-center"
          v-if="icon.cartFooter"
        >
          <div>
            <i class="fas fa-chevron-left"></i>
            <b-link @click.stop="goCart">{{ $t("goto_cart") }}</b-link>
          </div>
          <b-button
            variant="success"
            @click.stop="goCheckout"
            v-bind:disabled="btnDisable"
          >
            {{ icon.cartFooter.btnText }}
          </b-button>
        </div>
        <div class="language-block" v-if="icon.language">
          <ul class="d-flex list-unstyled">
            <li v-for="lang of icon.language" :key="lang.alt">
              <b-link :href="lang.href">
                <img :src="lang.img" :alt="lang.alt" />
              </b-link>
            </li>
          </ul>
        </div>
      </div>

      <div v-else class="no-cart-item">
        <p>{{ $t("No Item(s) Found") }}</p>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";
import Cart from "@storefront/core/modules/cart/mixins";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
    visible: { type: Boolean },
  },
  mixins: [Cart],
  computed: {
    btnDisable() {
      if (this.cartCount > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  mounted() {
    // document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    // document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      // visible: false,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    goCart() {
      // this.visible = false;
      this.$router.push("/checkout/cart");
    },
    goCheckout() {
      // this.visible = false;
      this.$router.push("/checkout");
    },
    async increaseQty(id, quantity) {
      const isQtyAvailable = await this.checkAvailableQty(id, quantity);
      if (isQtyAvailable) {
        this.quantityUp(id, quantity);
      }
    },
    async checkAvailableQty(id, qty) {
      const cartItem = this.cartItems.find((v) => v.id == id);

      if (!cartItem.product.backorders_allowed) {
        if (typeof cartItem.product.stock_qty != "undefined") {
          if (cartItem.product.stock_qty > qty) {
            return true;
          } else {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("product_not_in_stock_msg", {
                numberOf: cartItem.stock_qty,
              }),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            return false;
          }
        }
      }
      return true;
    },
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        // this.visible = false;
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
